import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Subscription, catchError, tap } from 'rxjs';

import { BotaoAcaoComponent } from '@app/shared/botao-acao/botao-acao.component';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { UiService } from '@core/services/ui.service';
import { VisualizarEditalApiService } from '../services/visualizar-edital-api.service';
import { VisualizarMensagemAlertaComponent } from '@visualizarEdital/visualizar-mensagem-alerta/visualizar-mensagem-alerta.component';

@Component({
	templateUrl: './visualizar-edital.component.html',
	providers: [MessageService]
})
export class VisualizarEditalComponent implements OnInit, OnDestroy {
	nomeFuncionalidade = 'Visualizar edital';
	template = 'principal';
	activeItem!: MenuItem;
	edital!: Edital;
	navbar!: MenuItem[];
	oportunidades: MenuItem[] = [];
	oportunidadeSelecionada!: Oportunidade;
	loading = true;
	gerandoPDF = false;
	mensagens!: Message[];
	mensagem?: Message;
	subscriptions: Subscription[] = [];
	editalArquivado = false;
	showModalCopiarEdital = false;
	copiarDadosBasicos = true;
	copiarOportunidades = true;
	copiarEtapas = true;
	editalCopia!: Edital;
	erroCopiarEdital = false;
	@ViewChild(BotaoAcaoComponent) botaoAcao!: BotaoAcaoComponent;
	@ViewChild(VisualizarMensagemAlertaComponent) visualizarMensagemAlerta!: VisualizarMensagemAlertaComponent;

	constructor(
		private cdRef: ChangeDetectorRef,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private ui: UiService,
		private oportunidadesApi: OportunidadesApiService,
		private router: Router,
		private visualizarEditalApi: VisualizarEditalApiService
	) {}

	ngOnInit() {
		const id = Number(this.route.snapshot.paramMap.get('id'));
		this.criarMenu();
		this.carregarEdital(id);
	}
	
	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}

	private criarMenu() {
		this.navbar = [
			{
				label: 'Visão geral',
				automationId: 'visao-geral',
				command: () => {
					this.exibirTemplate('principal');
				}
			},
			{
				label: 'Dados básicos',
				automationId: 'dados-basicos',
				command: () => {
					this.exibirTemplate('dados-basicos');
				}
			},
			{
				label: 'Oportunidades',
				automationId: 'oportunidades',
				items: this.oportunidades,
				icon: 'fas fa-chevron-down'
			},
			{
				label: 'Etapas',
				automationId: 'etapas',
				command: () => {
					this.exibirTemplate('etapas');
				}
			},
			{
				label: 'Anexos',
				automationId: 'anexos',
				command: () => {
					this.exibirTemplate('anexos');
				}
			},
			{
				label: 'Histórico do edital',
				automationId: 'historico',
				command: () => {
					this.exibirTemplate('historico');
				}
			}
		];
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get situacoesEnum(): typeof SituacoesEnum {
		return SituacoesEnum;
	}

	/**
	 * Testa se o edital está em rascunho para exibir ou não o link do Preview do Edital
	 * @returns true| false
	 */
	isRascunho() {
		return this.edital.situacao.descricao == 'Em Rascunho';
	}

	/**
	 * Exibe o template selecionado
	 * @param template nome do template
	 */
	exibirTemplate(template: string) {
		this.template = template;
	}

	/**
	 * Exibe o template de Oportunidades, com a oportunidade selecionada.
	 * @param event operacao='listar'
	 */
	irOportunidadeSelecionada(event: Oportunidade): void {
		this.oportunidadeSelecionada = event;
		this.exibirTemplate('oportunidade');
	}

	atualizarMensagem(event: Message) {
		this.mensagem = event;
		this.messageService.add(this.mensagem);
		this.cdRef.detectChanges();
	}

	atualizarGerarPdf(event: any) {
		this.gerandoPDF = event;
	}

	atualizarEdital(event: Edital) {
		this.botaoAcao.ngOnInit();
		if (this.visualizarMensagemAlerta) {
			this.visualizarMensagemAlerta.ngAfterViewInit();
		}
		this.cdRef.detectChanges();
		this.oportunidades = [];
		this.criarMenu();
		this.carregarEdital(event.id);
	}

	copiaClipboardUnsecured(text = ''): void {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			document.execCommand('copy');
		} catch (err) {
			console.error('Não foi possível copiar link.', err);
		}
		document.body.removeChild(textArea);
	}

	copiaClipboard(text = ''): void {
		if (!navigator.clipboard) {
			this.copiaClipboardUnsecured(text);
		} else {
			navigator.clipboard.writeText(text).catch(err => {
				console.error('Não foi possível copiar link.', err);
			});
		}
	}

	prepararCriarCopiaEdital(): void {
		this.showModalCopiarEdital = true;
		this.erroCopiarEdital = false;
	}

	esconderModalCriarCopiaEdital(): void {
		this.showModalCopiarEdital = false;
	}

	copiarEdital(): void {
		this.editalCopia = new Edital();

		if (!this.edital || this.loading)
			return;

		if (!this.copiarDadosBasicos && !this.copiarOportunidades && !this.copiarEtapas) {
			this.erroCopiarEdital = true;
			return;
		}
		this.erroCopiarEdital = false;
		
		this.subscriptions.push(
			this.oportunidadesApi.copiarEdital(this.edital.id, this.copiarDadosBasicos, this.copiarOportunidades, this.copiarEtapas)
			.pipe(
				tap(res => {
					this.converterInterfaceEdital.converter(this.editalCopia, res);
					this.cadastrarCopiaDeEdital();
				}),
				catchError(() => {
					this.messageService.add({
						severity: 'error',
						summary: `Erro ao copiar o edital ${this.edital.id}. `,
						detail: ' Tente novamente mais tarde.'
					});
					return [];
				})
			)
			.subscribe()
		);		
	}

	/**
	 * Gerar PDF Edital
	 */
	async gerarPdfEdital() {
		this.gerandoPDF = true;
		if (this.edital !== undefined) {
			this.subscriptions.push(
				this.visualizarEditalApi.gerarPdfEdital(this.edital.id).subscribe(
					res => {
						const newBlob = new Blob([res], { type: 'application/pdf' });
						const nav = window.navigator as any;
						if (nav && nav.msSaveOrOpenBlob) {
							nav.msSaveOrOpenBlob(newBlob, 'edital.pdf');
							return;
						}
						const data = window.URL.createObjectURL(newBlob);
						const link = document.createElement('a');
						link.href = data;
						link.download = 'edital.pdf';
						link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
						setTimeout(function () {
							window.URL.revokeObjectURL(data);
							link.remove();
						}, 100);
						this.gerandoPDF = false;
					},
					err => {
						return err.error.message;
					}
				)
			);
		}
	}

	/**
	 * Carrega as oportunidades do edital no select do menu.
	 */
	private carregarMenuOportunidades(oportunidadesEdital?: Oportunidade[]) {
		if (oportunidadesEdital && oportunidadesEdital.length > 0) {
			for (const oportunidade of oportunidadesEdital) {
				this.oportunidades.push({
					label: oportunidade.nomeOportunidade,
					command: () => {
						this.irOportunidadeSelecionada(oportunidade);
					}
				});
			}
		}
	}

	/**
	 * Obtem os dados do edital na base
	 * @param id id do edital
	 */
	private carregarEdital(id: number) {
		let edital_temp: Edital;
		this.subscriptions.push(
			this.oportunidadesApi.consultarEditalDadosBasicos(id).subscribe(
				res => {
					if (res) {
						edital_temp = new Edital(id);
						this.converterInterfaceEdital.converter(edital_temp, res);
						this.edital = edital_temp;
						this.editalArquivado = this.edital.editalArquivado ? this.edital.editalArquivado : false;
						this.carregarMenuOportunidades(this.edital.oportunidades);
						this.adicionarBotaoPreviewEdital();
						this.loading = false;
					}
				},
				err => {
					this.loading = false;
					this.mensagens = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private adicionarBotaoPreviewEdital() {
		if (this.edital.situacao.codigo === SituacoesEnum.RAS || this.edital.situacao.codigo === SituacoesEnum.PSO) {
			this.navbar.push({
				label: 'Baixar o preview do PDF',
				automationId: 'preview-pdf',
				command: () => {
					this.gerarPdfEdital();
				}
			});
		}
	}

	private cadastrarCopiaDeEdital() {
		this.router.navigate(['/cadastrarEdital'], { state: { edital: this.editalCopia } });
	}

}
