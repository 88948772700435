<p-messages [(value)]="topmessage" [enableService]="false" [closable]="false"></p-messages>
<p-toast position="top-right"></p-toast>

<div class="row">
	<div class="col-12">
		<h3 data-cy="titulo-pagina">Pesquisar currículo</h3>
		<p>
			Para pesquisar por currículos, adicione pelo menos um dos filtros abaixo:
			<i
				class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
				title="Dicas de pesquisa"
				(click)="exibirDica = true"
			></i>
		</p>

		<p-dialog
			header="Dicas de pesquisa"
			[(visible)]="exibirDica"
			[modal]="true"
			[draggable]="false"
			[closeOnEscape]="true"
			[closable]="true"
			[baseZIndex]="10000"
			[resizable]="false"
			styleClass="modal-40vw"
			[contentStyle]="{overflow: 'hidden'}"
			(onHide)="exibirDica = false"
			data-cy="modal-dica-pesquisa"
		>
			<div class="row">
				<div class="col">
					<p class="text-semi-bold">1. A pesquisa considera todos os filtros selecionados.</p>
					<p>
						Exemplo: Caso seja informado Vínculo do tipo Servidor Público e Idioma do tipo Inglês, a pesquisa retornará
						currículos que tenham o vínculo Servidor Público <span class="text-semi-bold">e</span> idioma inglês.
					</p>
					<p class="text-semi-bold">
						2. Se for adicionado mais de um item para um mesmo filtro, a pesquisa considerará como um "E".
					</p>
					<p>
						Exemplo: Caso seja informado vínculo do tipo Servidor Público Federal e outro vínculo do tipo Empregado público a
						pesquisa retornará os currículos que tenham vínculo do tipo Servidor Público Federal
						<span class="text-semi-bold">e</span> Empregado público.
					</p>
					<p class="text-semi-bold">
						3. Para os filtros que possuem campo de texto aberto, como Experiências profissionais, capacitações, certificações e
						o nome da formação acadêmica, a pesquisa será do tipo fonética.
					</p>
					<p>
						Exemplo: Ao informar o filtro Experiências profissionais com o texto "gestao", ele buscará por gestão, gestao,
						gestaoo, etc.
					</p>
				</div>
			</div>

			<ng-template pTemplate="footer">
				<p-button
					(click)="exibirDica = false"
					label="Fechar"
					styleClass="br-button secondary"
					data-cy="botao-fechar-dica"
				></p-button>
			</ng-template>
		</p-dialog>

		<div class="row" id="filtros-pesquisa">
			<div class="col-12">
				<div class="my-3 p-2 shadow-sm bg-gray-2 pb-5 mb-4">
					<div class="row">
						<div class="col-4">
							<div class="row">
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Tipos de vínculos"
										requisito="vinculo"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 mb-1"
												*ngFor="let vinculo of parametrosFiltro.vinculos"
											>
												<span class="text-down-01">{{ vinculo.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerVinculo(vinculo)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Experiências profissionais"
										requisito="experiencia"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let experiencia of parametrosFiltro.experiencias"
											>
												<span class="text-down-01" style="overflow-wrap: anywhere"
													>{{ experiencia.nomeExperiencia }}</span
												>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerExperiencia(experiencia)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Áreas de atuação"
										requisito="areaAtuacao"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let areaAtuacao of parametrosFiltro.areasAtuacao"
											>
												<span class="text-down-01">{{ areaAtuacao.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerAreaAtuacao(areaAtuacao)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Áreas de interesse"
										requisito="areaInteresse"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let area of parametrosFiltro.areasInteresse"
											>
												<span class="text-down-01">{{ area.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerAreaInteresse(area)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Softskills"
										requisito="softskill"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let competencia of parametrosFiltro.competencias"
											>
												<span class="text-down-01">{{ competencia.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerCompetencia(competencia)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
							</div>
						</div>
						<div class="col-4 px-5">
							<div class="row">
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Cargo"
										requisito="cargo"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4" *ngIf="parametrosFiltro.cargo">
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ parametrosFiltro.cargo }}</span
												>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerCargo()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Capacitações"
										requisito="capacitacao"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let capacitacao of parametrosFiltro.capacitacoes"
											>
												<span class="text-down-01" style="overflow-wrap: anywhere"
													>{{ capacitacao.nomeCapacitacao }}</span
												>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerCapacitacao(capacitacao)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Formação acadêmica"
										requisito="formacao"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let formacao of parametrosFiltro.formacoes"
											>
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ formacao.nomeNivelCurso?.toLowerCase() }}<span *ngIf="formacao.nomeFormacao"
														><span class="text-lowercase">em</span></span
													>{{ formacao.nomeFormacao }}</span
												>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerFormacao(formacao)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Idiomas"
										requisito="idioma"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let idioma of parametrosFiltro.idiomas"
											>
												<span class="text-down-01">{{ idioma.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerIdioma(idioma)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Certificações"
										requisito="certificacao"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let certificacao of parametrosFiltro.certificacoes"
											>
												<span class="text-down-01" style="overflow-wrap: anywhere"
													>{{ certificacao.nomeCertificacao }}</span
												>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerCertificacao(certificacao)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Conhecimentos técnicos"
										requisito="conhecimentos"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let conhecimento of parametrosFiltro.areasConhecimento"
											>
												<span class="text-down-01" style="overflow-wrap: anywhere">{{ conhecimento.nome }}</span>
												<i
													class="fas fa-times cursor-pointer"
													aria-hidden="true"
													(click)="removerConhecimento(conhecimento)"
												></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
							</div>
						</div>
						<div class="col-4 px-5">
							<div class="row">
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="UF"
										requisito="uf"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4" *ngIf="parametrosFiltro.ufOrgao">
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ parametrosFiltro.ufOrgao.toUpperCase() }}</span
												>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerUF()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Cidade"
										requisito="cidade"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngIf="parametrosFiltro.nomeMunicipio"
											>
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ parametrosFiltro.nomeMunicipio }}</span
												>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerCidade()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Órgão de exercício"
										requisito="orgao"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4" *ngIf="parametrosFiltro.orgao">
												<span class="text-down-01">{{ parametrosFiltro.orgao.nome }}</span>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerOrgao()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Uorg de exercício"
										requisito="uorg"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4" *ngIf="parametrosFiltro.nomeUorg">
												<span class="text-down-01">{{ parametrosFiltro.nomeUorg }}</span>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerUorg()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Nome"
										requisito="nome"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4" *ngIf="parametrosFiltro.nome">
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ parametrosFiltro.nome }}</span
												>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerNome()"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<sgp-item-filtro-pesquisa
										nomeFiltro="Medalhas Digitais"
										requisito="selo"
										[parametrosFiltro]="parametrosFiltro"
										(parametrosAtualizados)="atualizarFiltro($event)"
									>
										<div filtros-incluidos>
											<div
												class="br-tag text bg-blue-warm-10 text-gray-80 m-1 p-4"
												*ngFor="let selo of parametrosFiltro.selos"
											>
												<span class="text-down-01 text-capitalize" style="overflow-wrap: anywhere">
													{{ selo.nomeSelo }}<span *ngIf="selo.nomeInstituicao">
														<span class="text-lowercase">em&nbsp;</span>
														{{selo.nomeInstituicao.toUpperCase()}}</span
													>
												</span>
												<i class="fas fa-times cursor-pointer" aria-hidden="true" (click)="removerSelo(selo)"></i>
											</div>
										</div>
									</sgp-item-filtro-pesquisa>
								</div>
								<div class="col-12 px-5">
									<div class="row p-2">
										<div class="col-10">
											<label class="py-2 text-weight-semi-bold br-input" for="valor-proporcao"
												>Proporção mínima para tópicos</label
											>
											<input
												type="text"
												pInputText
												readonly="readonly"
												id="valor-proporcao"
												[ngModel]="proporcao / 100 | percent:'1.0-2'"
												class="w-full mb-2"
											/>
											<p-slider [(ngModel)]="proporcao" class="w-full" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" id="botoes">
			<div class="col-sm-12 col-md-4">
				<a [routerLink]="['/cestaCurriculo']"
					><button id="btnFavoritos" data-cy="botao-favoritos" class="primary" type="button" pButton>Cesta Currículo</button></a
				>
			</div>
			<div class="col text-right">
				<button
					class="primary mt-3 mt-sm-0 ml-sm-3"
					type="button"
					pButton
					data-cy="botao-pesquisar-elastic"
					[disabled]="!possuiRequisito"
					(click)="pesquisaInicial()"
				>
					Pesquisar
				</button>
				<button class="secondary mt-3 mt-sm-0 ml-sm-3" type="button" pButton data-cy="botao-pesquisar" (click)="limpar()">
					Limpar
				</button>
			</div>
		</div>

		<div class="row mt-5" id="resultadoPesquisa" *ngIf="retorno">
			<div id="quantidadeResultados" class="ml-3 mr-3 col-12 mb-3 pt-3 shadow-sm text-semi-bold" data-cy="quantidade-resultados">
				<div *ngIf="retorno.metadata.total > 1" class="col-12 mb-3">
					Foram encontrados {{ retorno.metadata.total! | numero }} de {{ retorno.metadata.qtdTotal! | numero }} currículos que
					possuem:
					<ul>
						<div [innerHTML]="descricaoFiltro"></div>
					</ul>
				</div>
				<div *ngIf="retorno.metadata.total === 1" class="col-12 mb-3">
					Foi encontrado 1 de {{ retorno.metadata.qtdTotal! | numero }} currículos que possui:
					<ul>
						<div [innerHTML]="descricaoFiltro"></div>
					</ul>
				</div>
				<div *ngIf="retorno.metadata.total === 0" class="col-12 mb-3">
					Nenhum currículo encontrado que possua:
					<ul>
						<div [innerHTML]="descricaoFiltro"></div>
					</ul>
					Experimente diminuir a quantidade de requisitos ou mudar o filtro para obter currículos.
				</div>
				<div class="col text-right">
					<a class="br-tag text bg-gray-warm-10 text-gray-80 ml-2">
						<span>Formação Acadêmica</span>
					</a>
					<a class="br-tag text bg-blue-warm-10 text-gray-80 ml-2">
						<span>Experiência Profissional</span>
					</a>
					<a class="br-tag text bg-green-warm-10 text-gray-80 ml-2">
						<span>Conhecimento Técnico</span>
					</a>
					<a class="br-tag text bg-red-warm-10 text-gray-80 ml-2">
						<span>Capacitação</span>
					</a>
				</div>
			</div>

			<div class="col-12">
				<p-dataView
					[value]="retorno.items"
					*ngIf="retorno.metadata.total > 0"
					[paginator]="true"
					[rows]="itemsPorPagina"
					[totalRecords]="retorno.metadata.total"
					[lazy]="true"
					(onPage)="paginar($event)"
					[first]="firstPage"
					data-cy="resultados-pesquisa"
				>
					<ng-template pTemplate="listItem" let-item>
						<div class="col-sm-12 col-md-12 py-4">
							<sgp-item-resultado-curriculo
								[item]="item"
								[proporcao]="proporcao"
								(repesquisar)="retornoItemCurriculo($event)"
							></sgp-item-resultado-curriculo>
						</div>
					</ng-template>
				</p-dataView>
			</div>
		</div>
	</div>
</div>
