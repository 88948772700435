import { HttpClient, HttpParams } from '@angular/common/http';

import { AreaConhecimentoItem } from '@core/models/area-conhecimento-items';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AreaConhecimentoApiService {
	constructor(private http: HttpClient) {}

	URL_AREA_CONHECIMENTO = environment.HOST_OPORTUNIDADE + '/' + 'area-conhecimento';

	obterAreasConhecimento(areaConhecimento: AreaConhecimentoItem, pagina: number, tamanho: number): Observable<any[]> {
		let params = new HttpParams();

		if (areaConhecimento.nome) {
			params = params.set('nome', areaConhecimento.nome);
		}

		if (areaConhecimento.ativo) {
			params = params.set('ativo', 1);
		}

		if (areaConhecimento.ferramenta) {
			params = params.set('ferramenta', 1);
		}

		if (areaConhecimento.incluido_usuario) {
			params = params.set('incluido_usuario', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any[]>(this.URL_AREA_CONHECIMENTO, { params: params });
	}

	obterAreaConhecimento(id: number): Observable<AreaConhecimentoItem> {
		const url = this.URL_AREA_CONHECIMENTO + '/' + id.toString();
		return this.http.get<AreaConhecimentoItem>(url);
	}

	incluirAreaConhecimento(areaConhecimento: AreaConhecimentoItem) {
		return this.http.post(this.URL_AREA_CONHECIMENTO, areaConhecimento);
	}

	alterarAreaConhecimento(id: number, areaConhecimento: AreaConhecimentoItem) {
		const url = this.URL_AREA_CONHECIMENTO + '/' + id.toString();
		return this.http.put(url, areaConhecimento);
	}

	excluirAreaConhecimento(id: number) {
		const url = this.URL_AREA_CONHECIMENTO + '/' + id.toString();
		return this.http.delete(url);
	}

	ativarInativarAreaConhecimento(idsAreaConhecimento: number[], ativar: boolean) {
		const url = ativar ? this.URL_AREA_CONHECIMENTO + '/ativar' 
						   : this.URL_AREA_CONHECIMENTO + '/inativar';
		return this.http.post(url, {ids: idsAreaConhecimento});
	}
}
