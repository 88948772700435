<p-dialog
	id="modalEditarTags"
	[header]="titulo"
	[(visible)]="exibirModalEditarFavoritos"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-50vw"
	[contentStyle]="{maxHeight: '600px', overflowX: 'hidden'}"
	[baseZIndex]="10000"
	[resizable]="false"
	data-cy="modal-criar-tags"
	(onHide)="fecharModalEditarFavoritos()"
>
	<div class="row">
		<div class="col-12">
			<label for="nome-tags-selecionadas">Você pode incluir etiquetas no item, se desejar (Opcional)</label>
			<p-chips
				id="nome-tags-selecionadas"
				[(ngModel)]="vetorNomeTags"
				separator=","
				[allowDuplicate]="false"
				[addOnTab]="true"
				[addOnBlur]="true"
				placeholder="Separe as etiquetas por vírgula"
				aria-label="Você pode adicionar etiquetas ao currículo na cesta, se desejar. Separe as etiquetas usando a vírgula (,), enter ou tab após escrevê-la. "
			></p-chips>
		</div>
	</div>

	<ng-template pTemplate="footer">
		<p-button
			(click)="fecharModalEditarFavoritos()"
			label="Cancelar"
			styleClass="br-button secondary mr-2"
			data-cy="botao-cancelar-tags"
		></p-button>
		<p-button
			(click)="excluirFavoritos()"
			*ngIf="idCestaCurriculo && permitidoExcluir"
			label="Excluir"
			styleClass="br-button bg-red-vivid-60 text-pure-0 mr-2"
			data-cy="botao-excluir-tags"
		></p-button>
		<p-button
			(click)="salvarFavoritos()"
			[label]="'Salvar'"
			styleClass="br-button primary"
			data-cy="botao-download-curriculo"
		></p-button>
	</ng-template>
</p-dialog>
