import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subscription, catchError, tap } from 'rxjs';

import { ItensResultadoQuestionario } from '@app/core/models/perfil-profissiografico/resultado-pesquisar-questionario-profissiografico';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { PesquisarQuestionarioProfissiograficoService } from '../services/pesquisar-questionario-profissiografico.service';
import { UiService } from '@app/core/services/ui.service';
import { UsuarioService } from '@sigepe/sigepe-template';
import moment from 'moment';

@Component({
	selector: 'sgp-resultado-questionario-profissiografico',
	templateUrl: './resultado-questionario.component.html'
})
export class ResultadoQuestionarioProfissiograficoComponent implements OnInit, OnDestroy {
	loadingResultado = false;
	erroPeriodo = false;
	finalizado = false;
	usuarioGestor = false;
	showModalLiberarMultiplosQuestionarios = false;
	periodo: Date[] = [];
	dataInicioLiberacao?: Date;
	dataFimLiberacao?: Date;
	subscriptions: Subscription[] = [];
	registrosSelecionados: ItensResultadoQuestionario [] = [];
	mensagemFeedback: Message[] = [];

	constructor(protected pesquisarService: PesquisarQuestionarioProfissiograficoService,
				private messageService: MessageService,
				private usuarioService: UsuarioService,
				private ui: UiService) {}

	ngOnInit(): void {
		this.verificarSeUsuarioGestor();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	esconderModalLiberarQuestionario() {
		this.showModalLiberarMultiplosQuestionarios = false;
	}

	prepararLiberarQuestionariosEmLote() {
		this.erroPeriodo = false;
		this.periodo = [];
		this.messageService.clear();
		this.mensagemFeedback = [];
  		this.showModalLiberarMultiplosQuestionarios = true;
	}
	liberarEmLote() {
		if (this.registrosSelecionados.length === 0) {	
			this.mensagemFeedback = this.ui.criarListaMensagem('warn', '', 'Nenhum questionário foi selecionado para liberação.');
			return;
		}
		if (this.periodo.length > 1 && this.periodo[0] && this.periodo[1]) {
			this.erroPeriodo = false;
			const listaIdRegistrosSelecionados: number[] = [];
			for (const item of this.registrosSelecionados) {
				listaIdRegistrosSelecionados.push(item.idCargo!);
			}
			const dataInicio: string = moment(this.periodo[0]).format('DD/MM/YYYY');
			const dataFinal: string = moment(this.periodo[1]).format('DD/MM/YYYY');
			this.subscriptions.push(
				this.pesquisarService
					.liberarEmLote(listaIdRegistrosSelecionados, dataInicio, dataFinal)
					.pipe(
						tap(res => {
							if (res) {
								this.messageService.add({ severity: 'success', summary: 'Questionário(s) liberado(s) com sucesso!' });
								this.esconderModalLiberarQuestionario();
								this.pesquisarService.pesquisar();
							}
						}),
						catchError(err => {
							this.mensagemFeedback = this.ui.criarListaMensagem('error', 'Erro ao tentar liberar questionário(s). ', err.error.message);
							return [this.mensagemFeedback];
						})
					)
					.subscribe()
			);
		} else {
			this.erroPeriodo = true;
		}
	}

	paginar(event: any) {
		const page = event.first / event.rows + 1;
		const size = event.rows;
		this.pesquisarService.paginar(page, size);
	}
	private verificarSeUsuarioGestor(): void {
		const perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (perfisUsuario?.includes(PerfisEnum.GESTOR_PESSOAS) || perfisUsuario?.includes(PerfisEnum.GESTOR_PRODUTO)) {
			this.usuarioGestor = true;
		}
	}
}
