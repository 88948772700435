import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametrosMatch } from '@app/core/models/parametros-match';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PesquisarCurriculoApiService {
	constructor(private http: HttpClient) {}

	matchPesquisarCurriculosElasticSearch(parametros: ParametrosMatch, pagina: number, tamanho?: number): Observable<any> {
		const url = environment.MATCH + '/pesquisar-curriculos';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		return this.http.post(url, parametros, { params: params });
	}

	consultarServicoIA(id: number, proporcaoTopicos?: number): Observable<any> {
		const url = environment.SERVICOS_TERCEIROS + '/topicos-curriculo/' + id;
		let params = new HttpParams();
		const percentual = proporcaoTopicos? proporcaoTopicos : 0;
		params = params.set('percentual_corte', percentual);
		return this.http.get(url, { params: params });
	}

	gerarPdfCurriculoService(cpf: string): Observable<Blob> {
		const url = environment.CURRICULOS + '/' + cpf + '/curriculo.pdf';
		return this.http.get(url, { responseType: 'blob' });
	}
}
