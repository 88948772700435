import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';

import { AlterarConclusaoLaudo } from '../models/perfil-profissiografico/alterar-laudo';
import { CandidatoLaudo } from '../models/perfil-profissiografico/candidato-laudo';
import { CargoProfissiografico } from '@app/core/models/perfil-profissiografico/cargo-profissiografico';
import { ConcursoProfissiografico } from '@app/core/models/perfil-profissiografico/concurso-profissiografico';
import { FiltroPesquisarLaudo } from '@app/core/models/perfil-profissiografico/filtro-pesquisar-laudo';
import { FiltroPesquisarQuestionarioProfissiografico } from '../models/perfil-profissiografico/filtro-pesquisar-questionario-profissiografico';
import { GravarAlterarLaudo } from '../models/perfil-profissiografico/gravar-alterar-laudo';
import { Injectable } from '@angular/core';
import { OrgaoProfissiografico } from '@app/core/models/perfil-profissiografico/orgao-profissiografico';
import { QuestionarioCargo } from '../models/perfil-profissiografico/questionario-cargo';
import { RespostaCandidato } from '../models/perfil-profissiografico/resposta-candidato';
import { ResultadoPesquisarLaudo } from '@app/core/models/perfil-profissiografico/resultado-pesquisar-laudo';
import { ResultadoPesquisarQuestionarioProfissiografico } from '../models/perfil-profissiografico/resultado-pesquisar-questionario-profissiografico';
import { SituacaoQuestionarioCargo } from '@app/core/models/perfil-profissiografico/situacao-questionario-cargo';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'any'
})
export class PerfilProfissiograficoApiService {
	private cacheConcurso$?: Observable<ConcursoProfissiografico[]>;
	private cacheSituacaoLaudo$?: Observable<SituacaoQuestionarioCargo[]>;
	private cacheSituacaoQuestionario$?: Observable<SituacaoQuestionarioCargo[]>;

	constructor(private http: HttpClient) {}

	getConcursos(codigoSituacao?: string, ordernar?: number) {
		if (!this.cacheConcurso$) {
			this.cacheConcurso$ = this.getConcursosService(codigoSituacao, ordernar).pipe(shareReplay(1));
		}
		return this.cacheConcurso$;
	}

	private getConcursosService(codigoSituacao?: string, ordernar?: number): Observable<ConcursoProfissiografico[]> {
		let params = new HttpParams();
		if (codigoSituacao) {
			params = params.append('situacao', codigoSituacao);
		}
		if (ordernar !== undefined && ordernar >= 0) {
			params = params.append('ordenar_por_atualizacao', ordernar);
		}

		return this.http.get<ConcursoProfissiografico[]>(`${environment.PERFIL_PROFISSIOGRAFICO}/concurso`, { params: params });
	}

	getOrgaosConcurso(idConcurso: number): Observable<OrgaoProfissiografico[]> {
		let params = new HttpParams();
		params = params.append('id_concurso', idConcurso);
		return this.http.get<OrgaoProfissiografico[]>(`${environment.PERFIL_PROFISSIOGRAFICO}/orgao`, { params: params });
	}

	getCargos(idConcurso: number, codigoOrgao: number): Observable<CargoProfissiografico[]> {
		let params = new HttpParams();
		params = params.append('id_concurso', idConcurso);
		params = params.append('codigo_orgao', codigoOrgao);
		return this.http.get<CargoProfissiografico[]>(`${environment.PERFIL_PROFISSIOGRAFICO}/cargo`, { params: params });
	}

	getSituacaoLaudo() {
		if (!this.cacheSituacaoLaudo$) {
			this.cacheSituacaoLaudo$ = this.getSituacaoLaudoService().pipe(shareReplay(1));
		}
		return this.cacheSituacaoLaudo$;
	}

	private getSituacaoLaudoService(): Observable<SituacaoQuestionarioCargo[]> {
		return this.http.get<SituacaoQuestionarioCargo[]>(`${environment.PERFIL_PROFISSIOGRAFICO}/situacao-laudo`);
	}

	pesquisarLaudo(filtro: FiltroPesquisarLaudo): Observable<ResultadoPesquisarLaudo> {
		let params = new HttpParams();
		params = params.append('id_concurso', filtro.idConcurso!);
		params = params.append('page', filtro.page);
		params = params.append('size', filtro.size);
		if (filtro.codigoOrgao) {
			params = params.append('codigo_orgao', filtro.codigoOrgao);
		}
		if (filtro.codigoCargo) {
			params = params.append('codigo_cargo', filtro.codigoCargo);
		}
		if (filtro.situacao) {
			params = params.append('situacao', filtro.situacao);
		}
		if (filtro.cpfCandidato) {
			params = params.append('cpf_candidato', filtro.cpfCandidato);
		}
		if (filtro.dataInicioEnvio) {
			params = params.append('data_inicio_envio', filtro.dataInicioEnvio);
		}
		if (filtro.dataFimEnvio) {
			params = params.append('data_fim_envio', filtro.dataFimEnvio);
		}
		if (filtro.dataInicioGeracao) {
			params = params.append('data_inicio_geracao', filtro.dataInicioGeracao);
		}
		if (filtro.dataFimGeracao) {
			params = params.append('data_fim_geracao', filtro.dataFimGeracao);
		}
		if (filtro.dataInicioEmissao) {
			params = params.append('data_inicio_emissao', filtro.dataInicioEmissao);
		}
		if (filtro.dataFimEmissao) {
			params = params.append('data_fim_emissao', filtro.dataFimEmissao);
		}
		if (filtro.prioridade) {
			params = params.append('prioridade_solicitada', filtro.prioridade ? 1 : 0);
		}
		if (filtro.atribuidosMim) {
			params = params.append('atribuidos_mim', filtro.atribuidosMim ? 1 : 0);
		}

		return this.http.get<ResultadoPesquisarLaudo>(`${environment.PERFIL_PROFISSIOGRAFICO}/pesquisar-laudo`, { params: params });
	}

	pesquisarQuestionario(filtro: FiltroPesquisarQuestionarioProfissiografico): Observable<ResultadoPesquisarQuestionarioProfissiografico> {
		let params = new HttpParams();
		params = params.append('id_concurso', filtro.idConcurso!);
		params = params.append('page', filtro.page);
		params = params.append('size', filtro.size);
		if (filtro.codigoOrgao) {
			params = params.append('codigo_orgao', filtro.codigoOrgao);
		}
		if (filtro.codigoCargo) {
			params = params.append('codigo_cargo', filtro.codigoCargo);
		}
		if (filtro.situacao) {
			params = params.append('situacao', filtro.situacao);
		}
		if (filtro.dataInicioLiberacao) {
			params = params.append('data_inicio_liberacao', filtro.dataInicioLiberacao);
		}
		if (filtro.dataFimLiberacao) {
			params = params.append('data_fim_liberacao', filtro.dataFimLiberacao);
		}

		return this.http.get<ResultadoPesquisarQuestionarioProfissiografico>(
			`${environment.PERFIL_PROFISSIOGRAFICO}/pesquisar-perfil-cargo`,
			{
				params: params
			}
		);
	}

	getSituacaoQuestionario(): Observable<SituacaoQuestionarioCargo[]> {
		if (!this.cacheSituacaoQuestionario$) {
			this.cacheSituacaoQuestionario$ = this.getSituacaoQuestionarioService().pipe(shareReplay(1));
		}
		return this.cacheSituacaoQuestionario$;
	}

	private getSituacaoQuestionarioService(): Observable<SituacaoQuestionarioCargo[]> {
		return this.http.get<SituacaoQuestionarioCargo[]>(`${environment.PERFIL_PROFISSIOGRAFICO}/situacao-questionario-cargo`);
	}

	/************************************
	 * Ações para o laudo
	 * *********************************/
	detalharLaudo(idCandidato: number): Observable<CandidatoLaudo> {
		let params = new HttpParams();
		params = params.append('id_candidato', idCandidato);
		return this.http.get<RespostaCandidato>(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/detalhar-laudo`, {
			params: params
		});
	}

	detalharQuestionariocandidato(idCandidato: number): Observable<RespostaCandidato> {
		let params = new HttpParams();
		params = params.append('id_candidato', idCandidato);
		return this.http.get<RespostaCandidato>(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/detalhar-questionario-candidato`, {
			params: params
		});
	}

	detalharCargo(idCargo: number): Observable<QuestionarioCargo> {
		let params = new HttpParams();
		params = params.append('id_cargo', idCargo);
		return this.http.get<QuestionarioCargo>(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCargo}/detalhar-cargo`, {
			params: params
		});
	}

	detalharAtividadesCargo(idCargo: number): Observable<QuestionarioCargo> {
		let params = new HttpParams();
		params = params.append('id_cargo', idCargo);
		return this.http.get<QuestionarioCargo>(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCargo}/atividades-cargo`, {
			params: params
		});
	}

	liberarQuestionarioCargo(idCargo: number, dataInicioLiberacao: string, dataFimLiberacao: string) {
		let params = new HttpParams();
		params = params.append('id', idCargo);
		params = params.append('dataInicioLiberacao', dataInicioLiberacao);
		params = params.append('dataFimLiberacao', dataFimLiberacao);
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/liberar-questionario-cargo`, {
			id: idCargo,
			dataInicioLiberacao: dataInicioLiberacao,
			dataFimLiberacao: dataFimLiberacao
		});
	}

	liberarQuestionarioEmLote(idsCargo: number[], dataInicioLiberacao: string, dataFimLiberacao: string): Observable<any> {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/liberar-questionario-cargo-lote`, {
			dataInicioLiberacao: dataInicioLiberacao,
			dataFimLiberacao: dataFimLiberacao,
			ids: idsCargo
		});
	}

	alterarEncerrarQuestionarioCargo(idCargo: number, justificativaEncerramento: string, dataEncerramento: string) {
		let params = new HttpParams();
		params = params.append('id', idCargo);
		params = params.append('justificativaEncerramento', justificativaEncerramento);
		params = params.append('dataFimLiberacao', dataEncerramento);
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/alterar-encerramento-questionario`, {
			id: idCargo,
			justificativaEncerramento: justificativaEncerramento,
			dataFimLiberacao: dataEncerramento
		});
	}

	atribuirLaudo(idsCandidatos: number[], nomeUsuario: string, cpfUsuario: string) {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/atribuir-laudo`, {
			ids: idsCandidatos,
			nomeResponsavel: nomeUsuario,
			cpfResponsavel: cpfUsuario
		});
	}

	desatribuirLaudo(idsCandidatos: number[]) {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/desatribuir-laudo`, {
			ids: idsCandidatos
		});
	}

	priorizarLaudo(idCandidato: number) {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/priorizar-laudo`, {
			id_candidato: idCandidato
		});
	}

	despriorizarLaudo(idsCandidato: number) {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/${idsCandidato}/despriorizar-laudo`, {
			id_candidato: idsCandidato
		});
	}

	darCienciaLaudo(idCandidato: number) {
		return this.http.put(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/dar-ciencia`, {
			id_candidato: idCandidato
		});
	}

	//TODO: detalhar laudo

	//TODO: alterar laudo

	downloadCurriculo(idCandidato: number) {
		return this.http.get(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/download-curriculo/`, {
			responseType: 'arraybuffer', observe: 'response'
		});
	}

	downloadLaudo(idCandidato: number) {
		return this.http.get(`${environment.PERFIL_PROFISSIOGRAFICO}/${idCandidato}/download-laudo/`, {
			responseType: 'arraybuffer', observe: 'response'
		});
	}

	downloadGraficoRadar(id_candidato: number) {
		return this.http.get(`${environment.PERFIL_PROFISSIOGRAFICO}/${id_candidato}/download-grafico-radar`, {
			responseType: 'blob'
		});
	}

	consultarConclusaoLaudo(id: number): Observable<AlterarConclusaoLaudo> {
		return this.http.get<AlterarConclusaoLaudo>(`${environment.PERFIL_PROFISSIOGRAFICO}/${id}/laudo`);
	}

	alterarLaudo(laudo: GravarAlterarLaudo) {
		const url = environment.PERFIL_PROFISSIOGRAFICO + '/laudo';
		return this.http.put(url, laudo);
	}
}
