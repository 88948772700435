import { publishLast, refCount } from 'rxjs/operators';

import { Anexo } from '@core/models/anexo';
import { Combo } from '@app/core/models/combo';
import { Etapa } from '@core/models/etapa';
import { HttpClient } from '@angular/common/http';
import { IParamsExecutarAcao } from '@core/interfaces/params.executar.acao.interface';
import { Injectable } from '@angular/core';
import { Localidade } from '@core/models/localidade';
import { MensagemAlerta } from '../models/MensagemAlerta';
import { Observable } from 'rxjs';
import { Oportunidade } from './../models/oportunidade';
import { Orgao } from '@core/models/orgao';
import { UnidadeOrganizacional } from '@core/models/unidade-organizacional';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class OportunidadesApiService {
	private cacheLocalidade$?: Observable<Array<Localidade>>;
	private cacheOrgaos$?: Observable<Array<Orgao>>;
	private cacheUnidadesAutorizadoras$?: Observable<Array<UnidadeOrganizacional>>;

	constructor(private http: HttpClient) {}

	orgaos() {
		if (!this.cacheOrgaos$) {
			this.cacheOrgaos$ = this.orgaosService().pipe(publishLast(), refCount());
		}
		return this.cacheOrgaos$;
	}

	private orgaosService(): Observable<Orgao[]> {
		const url = environment.ORGAO;
		return this.http.get<Orgao[]>(url);
	}

	consultarOrgao(codigo: string): Observable<any> {
		const url = environment.ORGAO + '/' + codigo;
		return this.http.get(url);
	}

	unidadesOrganizacionaisPorNome(orgao: string, texto: string): Observable<UnidadeOrganizacional[]> {
		const url = environment.UNIDADE_ORGANIZACIONAL + '/' + orgao + '/' + texto;
		return this.http.get<UnidadeOrganizacional[]>(url);
	}

	unidadesAutorizadoras() {
		if (!this.cacheUnidadesAutorizadoras$) {
			this.cacheUnidadesAutorizadoras$ = this.unidadesAutorizadorasService().pipe(publishLast(), refCount());
		}
		return this.cacheUnidadesAutorizadoras$;
	}

	private unidadesAutorizadorasService(): Observable<UnidadeOrganizacional[]> {
		const url = environment.UNIDADE_AUTORIZADORA;

		return this.http.get<UnidadeOrganizacional[]>(url);
	}

	localidades(texto: string) {
		if (!this.cacheLocalidade$) {
			this.cacheLocalidade$ = this.localidadesService(texto).pipe(publishLast(), refCount());
		}
		return this.cacheLocalidade$;
	}

	private localidadesService(texto: string): Observable<Localidade[]> {
		const url = environment.LOCALIDADE + texto;
		return this.http.get<Localidade[]>(url);
	}

	situacaoEdital(): Observable<Combo[]> {
		const url = environment.SITUACAO_EDITAL;

		return this.http.get<Combo[]>(url);
	}

	excluirEdital(idEdital: number): Observable<any> {
		const url = environment.EDITAL + '/' + idEdital.toString();
		return this.http.delete(url);
	}

	consultarEditalDadosBasicos(id: number): Observable<any> {
		const url = environment.EDITAL + '/' + id.toString() + '/dados-basicos';
		return this.http.get(url);
	}

	consultarAcoesEdital(id: number): Observable<any> {
		const url = environment.EDITAL + '/' + id.toString() + '/acoes';
		return this.http.get(url);
	}

	executarAcaoEdital(id: number, params: IParamsExecutarAcao): Observable<any> {
		const url = environment.EDITAL + '/' + id.toString() + '/execucao-acao';
		return this.http.post(url, params);
	}

	consultarAnexosEdital(idEdital: number): Observable<Anexo[]> {
		const url = environment.ANEXOS + '/editais/' + idEdital.toString();
		return this.http.get<Anexo[]>(url);
	}

	consultarEtapasEdital(idEdital: number): Observable<Etapa[]> {
		const url = environment.EDITAL + '/' + idEdital.toString() + '/etapas';
		return this.http.get<Etapa[]>(url);
	}

	gerarPdfRetificacao(id: number, msg: string): Observable<ArrayBuffer> {
		const url = environment.EDITAL + '/' + id.toString() + '/retificacao.pdf';
		return this.http.put(url, { textoRetificacao: msg }, { responseType: 'arraybuffer' });
	}

	obterMensagemAlerta(): Observable<MensagemAlerta> {
		const url = environment.MENSAGEM_ALERTA;
		return this.http.get(url);
	}

	obterMensagemAlertaAtiva(): Observable<MensagemAlerta> {
		const url = environment.MENSAGEM_ALERTA + '?ativa=true';
		return this.http.get(url);
	}

	consultarOportunidadesEdital(idEdital: number): Observable<Oportunidade[]> {
		const url = environment.EDITAL + '/' + idEdital.toString() + '/oportunidades';
		return this.http.get<Oportunidade[]>(url);
	}

	incluirOportunidade(idEdital: number, oportunidade: Oportunidade): Observable<any> {
		const url = environment.EDITAL + '/' + idEdital.toString() + '/oportunidades';
		return this.http.post(url, oportunidade);
	}

	alterarOportunidade(oportunidade: Oportunidade): Observable<any> {
		const url = environment.EDITAL + '/oportunidades/' + oportunidade.id?.toString();
		return this.http.put(url, oportunidade);
	}

	excluirOportunidades(idOportunidade: number): Observable<any> {
		const url = environment.EDITAL + '/oportunidades/' + idOportunidade?.toString();
		return this.http.delete(url);
	}

	detalharOportunidade(idOportunidade: number): Observable<Oportunidade> {
		const url = environment.EDITAL + '/oportunidades/' + idOportunidade?.toString();
		return this.http.get<Oportunidade>(url);
	}

	copiarEdital(idEdital: number, copiarDadosBasicos: boolean, copiarOportunidades: boolean, copiarEtapas: boolean): Observable<any> {
		const url = `${environment.EDITAL}/${idEdital}/copiar-edital`;
		return this.http.put(url, {
			'dadosBasicos': copiarDadosBasicos,
			'oportunidades': copiarOportunidades,
			'etapas': copiarEtapas
		});
	}
}
