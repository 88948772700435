<div class="mb-2">
	<a
		[routerLink]=""
		(click)="exibirModalCurriculo()"
		class="text-decoration-none text-weight-semi-bold text-primary-default text-capitalize text-up-01 cursor-pointer"
		*ngIf="item.nome; else semNome"
	>
		<i class="far fa-file"></i> {{ item.nome.toLowerCase() }}
	</a>

	<ng-template #semNome>
		<span
			(click)="exibirModalCurriculo()"
			class="text-decoration-none text-weight-semi-bold text-primary-default text-capitalize text-up-01 cursor-pointer"
			>Sem nome no banco de talentos</span
		>
	</ng-template>

	<a
		[routerLink]=""
		(click)="exibirModalFavoritar()"
		role="button"
		class="cesta text-base ml-3 {{ classeFavoritado}} "
		title="{{titleOperacaoCesta}}"
	>
		<span><i class="icone fas fa-{{operacaoCesta}} text-down-01"></i> Cesta</span>
	</a>

	<a *ngFor="let topico of vetorTopicos">
		<a
			title="Proporção: {{ topico[1] | percent:'1.0-2'}}"
			class="br-tag text bg-gray-warm-10 text-gray-80 ml-2"
			*ngIf="exibirIA && topico[2] === 'FOR'"
		>
			<span>{{ topico[0] }}</span>
		</a>
		<a
			title="Proporção: {{ topico[1] | percent:'1.0-2'}}"
			class="br-tag text bg-blue-warm-10 text-gray-80 ml-2"
			*ngIf="exibirIA && topico[2] === 'EXP'"
		>
			<span>{{ topico[0] }}</span>
		</a>
		<a
			title="Proporção: {{ topico[1] | percent:'1.0-2' }}"
			class="br-tag text bg-green-warm-10 text-gray-80 ml-2"
			*ngIf="exibirIA && topico[2] === 'CON'"
		>
			<span>{{ topico[0] }}</span>
		</a>
		<a
			title="Proporção: {{ topico[1] | percent:'1.0-2' }}"
			class="br-tag text bg-red-warm-10 text-gray-80 ml-2"
			*ngIf="exibirIA && topico[2] === 'CAP'"
		>
			<span>{{ topico[0] }}</span>
		</a>
	</a>
</div>
<div class="text-base mb-2 text-capitalize">
	<span *ngIf="item.cargoPublico; else semCargo">{{ item.cargoPublico.toLowerCase() }}</span>
	<ng-template #semCargo>Sem cargo público</ng-template>
	<span *ngIf="item.orgaoExercicio"> em </span>
	{{ item.orgaoExercicio?.toLowerCase() }}
</div>
<div class="text-base" *ngIf="resumoCompleto && resumoCompleto.length > TAMANHO_MAXIMO_RESUMO; else exibirCompleto">
	<span class="text-semi-bold">Resumo:</span>
	<!-- TODO: transformar Leia Mais em formato que seja acessível pelo teclado -->
	{{(leiaMais) ? resumoCompleto : resumoCompleto | slice:0:TAMANHO_MAXIMO_RESUMO}} <span *ngIf="!leiaMais">...</span>
	<div class="br-tag text bg-blue-warm-10 text-gray-80 text-down-01 ml-2 cursor-pointer" *ngIf="!leiaMais" (click)="leiaMais=true">
		<span><i class="fas fa-angle-down text-down-01"></i> Ler mais</span>
	</div>
	<div class="br-tag text bg-blue-warm-10 text-gray-80 text-down-01 ml-2 cursor-pointer" *ngIf="leiaMais" (click)="leiaMais=false">
		<span><i class="fas fa-angle-up text-down-01"></i> Ler menos</span>
	</div>
</div>
<ng-template #exibirCompleto>
	<div>
		<span class="text-semi-bold">Resumo:</span> {{ item.resumoCurriculo }}
		<span *ngIf="!item.resumoCurriculo">Nenhum resumo informado</span>
	</div></ng-template
>

<sgp-exibir-curriculo
	[cpf]="item.nrCpf!"
	[nome]="item.nome!"
	[exibir]="exibirCurriculo"
	(atualizarExibir)="fecharModalCurriculo($event)"
	*ngIf="exibirCurriculo && item"
></sgp-exibir-curriculo>

<sgp-modal-editar-favorito
	[exibirModalEditarFavoritos]="exibirModalEditarFavoritos"
	(resposta)="favoritando($event)"
	[situacaoFavorito]="favoritado"
	[idCurriculo]="item.idCurriculo"
	[idCestaCurriculo]="item.idCestaCurriculo"
	[permitidoExcluir]="true"
	[vetorNomeTags]="vetorNomeTags"
	[tagsSelecionadas]="tagsSelecionadas"
	*ngIf="exibirModalEditarFavoritos"
></sgp-modal-editar-favorito>
