import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TabelasAdministrativas } from '@core/models/tabelasAdministrativas';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SoftskillApiService {
	constructor(private http: HttpClient) {}

	URL_SOFTSKILL = environment.HOST_OPORTUNIDADE + '/' + 'competencia';

	obterSoftskills(softskill: TabelasAdministrativas, pagina: number, tamanho: number): Observable<TabelasAdministrativas[]> {
		let params = new HttpParams();

		if (softskill.nome) {
			params = params.set('nome', softskill.nome);
		}

		if (softskill.ativo) {
			params = params.set('ativo', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<TabelasAdministrativas[]>(this.URL_SOFTSKILL, { params: params });
	}

	obterSoftskill(id: number): Observable<TabelasAdministrativas> {
		const url = this.URL_SOFTSKILL + '/' + id.toString();
		return this.http.get<TabelasAdministrativas>(url);
	}

	incluirSoftskill(softskill: TabelasAdministrativas) {
		return this.http.post(this.URL_SOFTSKILL, softskill);
	}

	alterarSoftskill(id: number, softskill: TabelasAdministrativas) {
		const url = this.URL_SOFTSKILL + '/' + id.toString();
		return this.http.put(url, softskill);
	}

	excluirSoftskill(id: number) {
		const url = this.URL_SOFTSKILL + '/' + id.toString();
		return this.http.delete(url);
	}

	ativarInativarSoftskill(idsSoftskill: number[], ativar: boolean) {
		const url = ativar ? this.URL_SOFTSKILL + '/ativar' 
						   : this.URL_SOFTSKILL + '/inativar';
		return this.http.post(url, {ids: idsSoftskill});
	}
}
