<sgp-skeleton-visualizar-edital *ngIf="loading"></sgp-skeleton-visualizar-edital>

<div class="mt-5" *ngIf="!loading && !edital">
	<p-messages [(value)]="mensagens" [enableService]="false" [closable]="false" data-cy="mensagens"></p-messages>
</div>

<p-messages [enableService]="true" [closable]="false"></p-messages>

<div *ngIf="!loading && edital">
	<header>
		<div class="row">
			<div class="col-sm-12 col-md-10">
				<h3 style="display: inline-block" data-cy="titulo-edital">
					{{ edital.id }} - Edital {{edital.numeroEdital}} - {{edital.nomeOrgao}}
				</h3>
				<span style="display: inline-block" *ngIf="edital.linkSougov">
					<a
						role="button"
						title="Copiar link do edital no SouGov para a área de transferência."
						(click)="copiaClipboard(edital.linkSougov)"
						class="cursor-pointer ml-2"
						><i class="fas fa-clipboard"></i
					></a>
				</span>
				<span style="display: inline-block" *possuiPerfil="perfisEnum.GESTOR">
					<a role="button" title="Criar cópia do edital." (click)="prepararCriarCopiaEdital()" class="cursor-pointer ml-2"
						><i class="fas fa-copy"></i
					></a>
				</span>
				<p data-cy="situacao-edital">Situação: {{edital.situacao.descricao}}</p>
			</div>
			<div class="col-sm-12 col-md-2 mt-4 text-right">
				<sgp-botao-acao
					id="botaoAcao"
					[edital]="edital"
					pagina="visualizar_edital"
					(mensagemFeedback)="atualizarMensagem($event)"
					(editalAtualizado)="atualizarEdital($event)"
					data-cy="botao-acoes"
					*ngIf="edital"
				></sgp-botao-acao>
			</div>
		</div>
	</header>

	<p-menubar [model]="navbar" data-cy="navbar"></p-menubar>

	<sgp-visualizar-mensagem-alerta
		[edital]="edital"
		*ngIf="edital && this.template === 'principal'"
		(mensagemFeedback)="atualizarMensagem($event)"
		(editalAtualizado)="atualizarEdital($event)"
	></sgp-visualizar-mensagem-alerta>

	<div id="main-content">
		<sgp-visualizar-dados-edital
			*ngIf="this.template === 'principal' && edital"
			[edital]="edital"
			(oportunidadeSelecionada)="irOportunidadeSelecionada($event)"
		></sgp-visualizar-dados-edital>
		<sgp-visualizar-dados-basicos *ngIf="this.template === 'dados-basicos'" [edital]="edital"></sgp-visualizar-dados-basicos>
		<sgp-visualizar-oportunidade
			*ngIf="this.template === 'oportunidade'"
			[oportunidadeSelecionada]="oportunidadeSelecionada"
			[situacaoEdital]="edital.situacao.codigo"
			[orgaoEdital]="edital.codigoOrgao!.toString()"
			[numeroEdital]="edital.numeroEdital"
			[editalArquivado]="editalArquivado"
		></sgp-visualizar-oportunidade>
		<sgp-visualizar-etapas-edital *ngIf="this.template === 'etapas'" [edital]="edital"></sgp-visualizar-etapas-edital>
		<sgp-visualizar-anexos [editalArquivado]="editalArquivado" *ngIf="this.template === 'anexos'"></sgp-visualizar-anexos>
		<sgp-visualizar-historico-edital
			*ngIf="this.template === 'historico'"
			[edital]="edital"
			(gerandoPDF)="atualizarGerarPdf($event)"
		></sgp-visualizar-historico-edital>
	</div>
</div>

<p-dialog
	id="copiarEdital"
	header="Criar cópia de edital"
	[(visible)]="showModalCopiarEdital"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-30vw text-left"
	[style]="{ 'minWidth':'350px', 'minHeight': '280px'}"
>
	<div class="row">
		<div class="col-sm-12">
			<div class="mt-2 br-input container">
				<div class="br-switch right mr-5 mt-2">
					<input
						id="copiar-dados-basicos"
						type="checkbox"
						[(ngModel)]="copiarDadosBasicos"
						data-cy="campo-copiar-dados-basicos"
					/>
					<label for="copiar-dados-basicos">Dados Básicos</label>
				</div>
				<div class="br-switch right mr-5 mt-2">
					<input
						id="copiar-oportunidades"
						type="checkbox"
						[(ngModel)]="copiarOportunidades"
						data-cy="campo-copiar-oportunidades"
					/>
					<label for="copiar-oportunidades">Oportunidades</label>
				</div>
				<div class="br-switch right mr-5 mt-2">
					<input id="copiar-etapas" type="checkbox" [(ngModel)]="copiarEtapas" data-cy="campo-copiar-etapas" />
					<label for="copiar-etapas">Etapas</label>
				</div>
				<sgp-message-feedback
					[show]="erroCopiarEdital"
					mensagem="Escolha pelo menos uma das opções para copiar."
					data-cy="erro-concurso-obrigatorio"
				></sgp-message-feedback>
			</div>
		</div>
	</div>
	<div class="row mt-4">
		<div class="col text-right">
			<p-button label="Cancelar" (click)="esconderModalCriarCopiaEdital()" styleClass="br-button secondary"></p-button>
			<p-button label="Confirmar" (click)="copiarEdital()" styleClass="br-button primary ml-2"></p-button>
		</div>
	</div>
</p-dialog>
