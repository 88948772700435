import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { BotaoAcaoProfissiograficoComponent } from '@app/shared/botao-acao-profissiografico/botao-acao-profissiografico.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { DataItensResultadoQuestionarioPipe } from '@app/core/pipes/dataItensResultadoQuestionario';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { FiltroQuestionarioProfissiograficoComponent } from './filtro-pesquisar-questionario/filtro-pesquisar-questionario.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MessageFeedbackComponent } from '@app/shared/message-feedback/message-feedback.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PesquisarQuestionarioProfissiograficoComponent } from './pesquisar-questionario/pesquisar-questionario.component';
import { ResultadoQuestionarioProfissiograficoComponent } from './resultado-questionario/resultado-questionario.component';
import { SharedModule } from '../shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TableModule } from 'primeng/table';
import { TextoAjudaComponent } from '@app/shared/texto-ajuda/texto-ajuda.component';
import { ToastModule } from 'primeng/toast';

@NgModule({
	declarations: [
		PesquisarQuestionarioProfissiograficoComponent,
		FiltroQuestionarioProfissiograficoComponent,
		ResultadoQuestionarioProfissiograficoComponent
	],
	providers: [DataItensResultadoQuestionarioPipe, MessageFeedbackComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	imports: [
		BotaoAcaoProfissiograficoComponent,
		CommonModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputMaskModule,
		MessagesModule,
		SharedModule,
		SigepeSegurancaModule,
		DsGovDirectivesModule,
		FormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		MessageModule,
		TableModule,
		TextoAjudaComponent,
		CoreModule,
		DialogModule,
		ToastModule
	]
})
export class PesquisarQuestionarioProfissiograficoModule {}
