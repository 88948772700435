import { AreaAtuacaoOportunidade } from '@core/models/area-atuacao-oportunidade';
import { AreaConhecimentoOportunidade } from '@core/models/area-conhecimento-oportunidade';
import { Candidato } from '@core/models/candidato';
import { Edital } from '@core/models/edital';
import { Etapa } from '@core/models/etapa';
import { Formacao } from '@core/models/formacao';
import { HistoricoEdital } from '@core/models/historico-edital';
import { Injectable } from '@angular/core';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadeCompetencia } from '@core/models/oportunidade-competencia';
import { OportunidadeIdioma } from '@core/models/oportunidade-idioma';
import { TipoDocumento } from '@app/core/models/tipo-documento';
import { TipoDocumentoEtapa } from './../models/tipo-documento-etapa';
import { TipoEtapa } from '@core/models/tipo-etapa';
import { TipoVinculo } from '@core/models/tipo-vinculo';
import { ValidateHelper } from './validate.helper';
import { Vinculo } from '@core/models/vinculo';

/**
 * Helper com métodos de conversão de edital entre .
 */
@Injectable({
	providedIn: 'root'
})
export class ConverterInterfaceEditalHelper {
	constructor(private validate: ValidateHelper) {}

	/**
	 * Converte a resposta do serviço para o edital
	 * @param edital edital do modelo
	 * @returns interface do edital
	 */
	public async converter(edital: Edital, resposta: any) {
		edital.id = resposta.id;
		edital.numeroEdital = this.formataNumeroEdital(resposta.numeroEdital);
		edital.codigoOrgao = resposta.codigoOrgao;
		edital.nomeOrgao = resposta.nomeOrgao;
		edital.emailContato = resposta.emailContato;
		edital.exigenciaLegal = resposta.exigenciaLegal;
		edital.preambulo = resposta.preambulo;
		edital.sobreUnidade = resposta.sobreUnidade;
		edital.sobreVagasOportunidades = resposta.sobreVagasOportunidades;
		edital.linkSougov = resposta.linkSougov;
		edital.situacao = resposta.situacao;
		if (resposta.etapas != undefined) {
			const etapas: Etapa[] = this.converterRetornoParaEtapas(resposta);
			edital.etapas = etapas;
		}
		if (resposta.oportunidades != undefined) {
			const oportunidades: Oportunidade[] = this.converterRetornoParaOportunidades(resposta);
			edital.oportunidades = oportunidades;
		}
		edital.dataHoraAtualizacao = resposta.dataHoraAtualizacao;
		edital.historico = resposta.historico;
		edital.editalArquivado = resposta.editalArquivado;
	}

	private converterRetornoParaEtapas(resposta: any) {
		const etapas: Etapa[] = [];
		resposta.etapas!.forEach(
			(etp: {
				id: any;
				idTipoEtapaEdital: any;
				dataInicioEtapa: any;
				dataFimEtapa: any;
				tipoEtapaEdital: TipoEtapa;
				descricaoEtapa: string | undefined;
				orientacoesEtapa: string | undefined;
				informacaoCandidatura: string | undefined;
				permiteEnvioDocumentos: number | undefined;
				tiposDocumento: TipoDocumento[];
			}) => {
				const etapa: Etapa = {
					id: etp.id,
					idTipoEtapaEdital: etp.tipoEtapaEdital.id,
					dataInicioEtapa: this.validate.validaCampoComRetorno(etp.dataInicioEtapa, null, etp.dataInicioEtapa),
					dataFimEtapa: this.validate.validaCampoComRetorno(etp.dataFimEtapa, null, etp.dataFimEtapa),
					descricaoEtapa: this.validate.validaCampoComRetorno(etp.descricaoEtapa, ''),
					orientacoesEtapa: this.validate.validaCampoComRetorno(etp.orientacoesEtapa, ''),
					informacaoCandidatura: this.validate.validaCampoComRetorno(etp.informacaoCandidatura, ''),
					tipoEtapaEdital: etp.tipoEtapaEdital,
					permiteEnvioDocumentos: this.validate.validaCampoComRetorno(etp.permiteEnvioDocumentos, 0),
					tiposDocumento: this.converterTipoDocumento(etp.tiposDocumento)
				};
				etapas.push(etapa);
			}
		);
		return etapas;
	}

	private converterTipoDocumento(tipoDocumentoEtapa?: TipoDocumentoEtapa[]) {
		const tipos: TipoDocumento[] = [];
		tipoDocumentoEtapa?.forEach(item => {
			const tipo = {
				id: item.idTipoDocumento,
				nome: item.tipoDocumento?.nome,
				ativo: item.tipoDocumento?.ativo
			};
			tipos.push(tipo);
		});
		return tipos;
	}

	converterRetornoParaHistorico(resposta: any) {
		const historicos: HistoricoEdital[] = [];
		resposta.forEach(
			(hst: { id: any; cpfOperador: string | undefined; dataHoraHistorico: string | undefined; situacao: string | undefined }) => {
				const historico: HistoricoEdital = {
					id: hst.id,
					cpfOperador: hst.cpfOperador,
					dataHoraHistorico: hst.dataHoraHistorico,
					situacao: hst.situacao
				};
				historicos.push(historico);
			}
		);
		return historicos;
	}

	public converterUmRetornoParaOportunidades(oportunidade: any) {
		const _oportunidade = oportunidade;
		_oportunidade.localidade = this.formataLocalidade(oportunidade.codigoLocalidade, oportunidade.nomeLocalidade);
		_oportunidade.unidade = this.formataUnidade(oportunidade.codigoUorg, oportunidade.nomeUorg);
		_oportunidade.incentivo = this.formataIncentivo(oportunidade.incentivo);
		_oportunidade.areasAtuacao = this.formataAreasAtuacao(oportunidade.areasAtuacao);
		_oportunidade.vinculos = this.formataVinculos(oportunidade.vinculos);
		_oportunidade.areasConhecimento = this.formataAreaConhecimento(oportunidade.areasConhecimento);
		_oportunidade.competencias = this.formataSoftskills(oportunidade.competencias);
		_oportunidade.idiomas = this.formataIdiomas(oportunidade.idiomas);
		_oportunidade.formacoes = this.formataFormacoes(oportunidade.formacoes);
		return _oportunidade;
	}

	public converterRetornoParaOportunidades(resposta: any) {
		const oportunidades: Oportunidade[] = [];
		resposta.oportunidades!.forEach((oportunidade: any) => {
			const _oportunidade = this.converterUmRetornoParaOportunidades(oportunidade);
			oportunidades.push(_oportunidade);
		});

		return oportunidades;
	}

	public converterRetornoParaCandidatos(resposta: any) {
		const candidatos: Candidato[] = [];
		resposta.candidatos!.forEach(
			(cand: {
				id: any;
				idOportunidade: any;
				cpfCandidato: number;
				curriculoJson: string | undefined;
				emailCandidato: string;
				dataHoraInscricao: string;
				nomeCandidato: string | undefined;
				marcado: boolean;
				favorito: boolean;
				localidadeResidencia?: string;
				nomeVinculo?: string;
			}) => {
				const candidato: Candidato = {
					id: cand.id,
					idOportunidade: cand.idOportunidade,
					cpfCandidato: cand.cpfCandidato,
					curriculoJson: cand.curriculoJson,
					nomeCandidato: cand.nomeCandidato,
					emailCandidato: cand.emailCandidato,
					dataHoraInscricao: cand.dataHoraInscricao,
					marcado: cand.marcado,
					favorito: cand.favorito,
					localidadeResidencia: cand.localidadeResidencia,
					nomeVinculo: cand.nomeVinculo
				};
				candidatos.push(candidato);
			}
		);
		return candidatos;
	}

	public converterRetornoParaVinculos(resposta: any) {
		const vinculos: Vinculo[] = [];
		resposta.vinculos!.forEach((vinculo: { id: any; nome: string | undefined; excetoEstagioProbatorio: any; vinculo: any }) => {
			const vinc: Vinculo = {
				id: vinculo.id,
				nome: vinculo.vinculo.nome,
				excetoEstagioProbatorio: vinculo.excetoEstagioProbatorio,
				vinculo: vinculo
			};
			vinculos.push(vinc);
		});
		return vinculos;
	}

	private formataNumeroEdital(numero: string): string {
		if (numero) return 'nº ' + Number(numero.substring(4)) + '/' + numero.substring(0, 4);
		else return 'sem número';
	}

	private formataElementoCombo(elemento: any): any {
		const retorno: any = {};
		retorno.code = elemento.codigo;
		retorno.name = elemento.descricao;
		return retorno;
	}

	private formataIncentivo(elemento: any): any {
		let retorno: any = {};
		if (elemento !== null) {
			retorno.codigo = elemento.id;
			retorno.descricao = elemento.nome;
		} else {
			retorno = null;
		}
		return retorno;
	}

	private formataUnidade(codigo: any, nome: any): any {
		const retorno: any = {};
		retorno.codigo = codigo;
		retorno.descricao = nome;
		return retorno;
	}

	private formataLocalidade(codigo: any, nome: any): any {
		const retorno: any = {};
		retorno.code = codigo?.toString();
		retorno.name = nome;
		return retorno;
	}

	private formataAreasAtuacao(areasAtuacao: any[]): AreaAtuacaoOportunidade[] {
		const areas: AreaAtuacaoOportunidade[] = [];
		areasAtuacao.forEach(area => {
			areas.push(new AreaAtuacaoOportunidade(area.id, area.idAreaAtuacao, area.nome, area.obrigatorio));
		});
		return areas;
	}

	private formataVinculos(vinculosoportunidade: any[]): Vinculo[] {
		const vinculos: Vinculo[] = [];
		vinculosoportunidade.forEach(vinculo => {
			const tipoVinculo = new TipoVinculo(vinculo.vinculo.id, vinculo.vinculo.nome, vinculo.vinculo.ativo);
			vinculos.push(
				new Vinculo(
					vinculo.id,
					vinculo.vinculo.id,
					vinculo.vinculo.nome,
					vinculo.excetoEstagioProbatorio,
					tipoVinculo,
					vinculo.obrigatorio
				)
			);
		});
		return vinculos;
	}

	private formataAreaConhecimento(areasConhecimento: any[]): AreaConhecimentoOportunidade[] {
		const _areasConhecimento: AreaConhecimentoOportunidade[] = [];
		areasConhecimento.forEach(areaConhecimento => {
			_areasConhecimento.push(
				new AreaConhecimentoOportunidade(
					areaConhecimento.id,
					areaConhecimento.idAreaConhecimento,
					areaConhecimento.nome,
					areaConhecimento.obrigatorio
				)
			);
		});
		return _areasConhecimento;
	}

	private formataSoftskills(competencias: any[]): OportunidadeCompetencia[] {
		const _competencia: OportunidadeCompetencia[] = [];
		competencias.forEach(competencia => {
			_competencia.push(
				new OportunidadeCompetencia(competencia.id, competencia.idCompetencia, competencia.nome, competencia.obrigatorio)
			);
		});
		return _competencia;
	}

	private formataIdiomas(competencias: any[]): OportunidadeIdioma[] {
		const _idioma: OportunidadeIdioma[] = [];
		competencias.forEach(idioma => {
			const novoIdioma = new OportunidadeIdioma();
			novoIdioma.id = idioma.id;
			novoIdioma.idIdioma = idioma.idIdioma;
			novoIdioma.nome = idioma.nome;
			novoIdioma.nivelCompreensao = idioma.nivelCompreensao?.codigo;
			novoIdioma.nivelEscrita = idioma.nivelEscrita?.codigo;
			novoIdioma.nivelFala = idioma.nivelFala?.codigo;
			novoIdioma.nivelLeitura = idioma.nivelLeitura?.codigo;
			novoIdioma.obrigatorio = idioma.obrigatorio;

			_idioma.push(novoIdioma);
		});
		return _idioma;
	}

	private formataFormacoes(formacoes: any[]): Formacao[] {
		const _formacao: Formacao[] = [];
		formacoes.forEach(formacao => {
			_formacao.push(
				new Formacao(formacao.id, formacao.nivelCurso.id, formacao.nivelCurso.nome, formacao.nomeFormacao, formacao.obrigatorio)
			);
		});
		return _formacao;
	}
}
