import { HttpClient, HttpParams } from '@angular/common/http';

import { AreaAtuacao } from '@core/models/area-atuacao';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AreaAtuacaoApiService {
	constructor(private http: HttpClient) {}

	URL_AREA_ATUACAO = environment.HOST_OPORTUNIDADE + '/' + 'area-atuacao';

	obterAreasAtuacao(areaAtuacao: AreaAtuacao, pagina: number, tamanho: number): Observable<any[]> {
		let params = new HttpParams();

		if (areaAtuacao.nome) {
			params = params.set('nome', areaAtuacao.nome);
		}

		if (areaAtuacao.ativo) {
			params = params.set('ativo', 1);
		}

		if (areaAtuacao.incluido_usuario) {
			params = params.set('incluido_usuario', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any[]>(this.URL_AREA_ATUACAO, { params: params });
	}

	obterAreaAtuacao(id: number): Observable<AreaAtuacao> {
		const url = this.URL_AREA_ATUACAO + '/' + id.toString();
		return this.http.get<AreaAtuacao>(url);
	}

	incluirAreaAtuacao(areaAtuacao: AreaAtuacao) {
		return this.http.post(this.URL_AREA_ATUACAO, areaAtuacao);
	}

	alterarAreaAtuacao(id: number, areaAtuacao: AreaAtuacao) {
		const url = this.URL_AREA_ATUACAO + '/' + id.toString();
		return this.http.put(url, areaAtuacao);
	}

	excluirAreaAtuacao(id: number) {
		const url = this.URL_AREA_ATUACAO + '/' + id.toString();
		return this.http.delete(url);
	}

	ativarInativarAreaAtuacao(idsAreaAtuacao: number[], ativar: boolean) {
		const url = ativar ? this.URL_AREA_ATUACAO + '/ativar' 
						   : this.URL_AREA_ATUACAO + '/inativar';
		return this.http.post(url, {ids: idsAreaAtuacao});
	}
}
