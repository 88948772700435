import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultadoEditaisEmAndamento } from '../models/resultado-editais-em-andamento';
import { ResultadoEditaisPendentes } from '../models/resultado-editais-pendentes';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class DashboardApiService {
	constructor(private http: HttpClient) {}

	dashboardTotalizadores(codigoOrgao?: number, codigoUorg?: number): Observable<any> {
		const url = environment.DASHBOARD + '/totalizadores';
		let params = new HttpParams();

		if (codigoOrgao) {
			params = params.set('codigoOrgao', codigoOrgao);
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}

		return this.http.get(url, { params: params });
	}

	dashboardEditaisAndamento(pagina: number, tamanho: number, codigoUorg?: number): Observable<ResultadoEditaisEmAndamento> {
		const url = environment.DASHBOARD + '/editais-andamento';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}
		return this.http.get<ResultadoEditaisEmAndamento>(url, { params: params });
	}

	dashboardEditaisPendentes(pagina: number, tamanho: number): Observable<ResultadoEditaisPendentes> {
		const url = environment.DASHBOARD + '/editais-pendentes';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		return this.http.get<ResultadoEditaisPendentes>(url, { params: params });
	}

	dashboardEditaisPorSituacao(codigoOrgao?: number, codigoUorg?: number): Observable<any> {
		const url = environment.DASHBOARD + '/editais-situacao';
		let params = new HttpParams();

		if (codigoOrgao) {
			params = params.set('codigoOrgao', codigoOrgao);
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}

		return this.http.get(url, { params: params });
	}

	dashboardOportunidadesPorTipoMovimentacao(codigoOrgao?: number, codigoUorg?: number, situacao?: string ): Observable<any> {
		const url = environment.DASHBOARD + '/oportunidades-tipo-movimentacao';
		let params = new HttpParams();

		if (codigoOrgao) {
			params = params.set('codigoOrgao', codigoOrgao);
		}

		if (situacao) {
			params = params.set('situacao', situacao);
		}
		
		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}

		return this.http.get(url, { params: params });
	}

	nomeUnidadeOrganizacional(codigoOrgao: string, codigoUnidade: string): Observable<string> {
		const url = environment.UNIDADE_ORGANIZACIONAL + '/' + codigoOrgao + '/' + codigoUnidade;
		return this.http.get<string>(url);
	}

	dashboardEvolucaoMensal(codigoOrgao?: number, codigoUorg?: number): Observable<any> {
		const url = environment.DASHBOARD + '/evolucao-mensal';
		let params = new HttpParams();

		if (codigoOrgao) {
			params = params.set('codigoOrgao', codigoOrgao);
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}

		return this.http.get(url, { params: params });
	}

	dashboardOportunidadesPorSituacao(codigoOrgao?: number, situacao?: string): Observable<any> {
		const url = environment.DASHBOARD + '/oportunidades-situacao';
		let params = new HttpParams();

		if (codigoOrgao) {
			params = params.set('codigoOrgao', codigoOrgao);
		}

		if (situacao) {
			params = params.set('situacao', situacao);
		}

		return this.http.get(url, { params: params });
	}

	dashboardEditaisPublicadosPorOrgao(pagina: number, tamanho: number): Observable<any> {
		const url = environment.DASHBOARD + '/editais-orgao';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		return this.http.get(url, { params: params });
	}

	dashboardCandidatosInscritosPorOrgao(pagina: number, tamanho: number, situacoes?: SituacoesEnum[]): Observable<any> {
		const url = environment.DASHBOARD + '/candidatos-orgao';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (situacoes) {
			params = params.set('situacoes', situacoes.join(', '));
		}
		return this.http.get(url, { params: params });
	}

	dashboardEditaisPublicadosPorUorg(orgao: number, pagina: number, tamanho: number, codigoUorg?: number, situacoes?: SituacoesEnum[] ): Observable<any> {
		const url = environment.DASHBOARD + '/editais-uorg';
		let params = new HttpParams();

		if (orgao) {
			params = params.set('codigoOrgao', orgao);
		}
		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (situacoes) {
			params = params.set('situacoes', situacoes.join(', '));
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}
		return this.http.get(url, { params: params });
	}

	dashboardCandidatosInscritosPorUorg(orgao: number, pagina: number, tamanho: number, codigoUorg?: number,situacoes?: SituacoesEnum[]): Observable<any> {
		const url = environment.DASHBOARD + '/candidatos-uorg';
		let params = new HttpParams();

		if (orgao) {
			params = params.set('codigoOrgao', orgao);
		}
		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (situacoes) {
			params = params.set('situacoes', situacoes.join(', '));
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}
		return this.http.get(url, { params: params });
	}

	dashboardOportunidadesPublicadasPorUorg(orgao: number, pagina: number, tamanho: number, codigoUorg?: number, situacoes?: SituacoesEnum[]): Observable<any> {
		const url = environment.DASHBOARD + '/oportunidades-uorg';
		let params = new HttpParams();

		if (orgao) {
			params = params.set('codigoOrgao', orgao);
		}
		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (situacoes) {
			params = params.set('situacoes', situacoes.join(', '));
		}

		if (codigoUorg) {
			params = params.set('codigoUorg', codigoUorg);
		}
		return this.http.get(url, { params: params });
	}

	dashboardOportunidadesPublicadasPorOrgao(pagina: number, tamanho: number, situacoes?: SituacoesEnum[]): Observable<any> {
		const url = environment.DASHBOARD + '/oportunidades-orgao';
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}
		if (situacoes) {
			params = params.set('situacoes', situacoes.join(', '));
		}
		return this.http.get(url, { params: params });
	}
}
