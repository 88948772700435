import { HttpClient, HttpParams } from '@angular/common/http';

import { Incentivo } from '../../core/models/incentivo';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class IncentivoApiService {
	constructor(private http: HttpClient) {}
	URL_INCENTIVO = environment.HOST_OPORTUNIDADE + '/' + 'incentivo';

	obterIncentivos(incentivo: Incentivo, pagina: number, tamanho: number): Observable<Incentivo[]> {
		let params = new HttpParams();

		if (incentivo.nome) {
			params = params.set('nome', incentivo.nome);
		}

		if (incentivo.ativo) {
			params = params.set('ativo', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<Incentivo[]>(this.URL_INCENTIVO, { params: params });
	}

	obterIncentivo(id: number): Observable<Incentivo> {
		const url = this.URL_INCENTIVO + '/' + id.toString();
		return this.http.get<Incentivo>(url);
	}

	incluirIncentivo(incentivo: Incentivo) {
		return this.http.post(this.URL_INCENTIVO, incentivo);
	}

	excluirIncentivo(id: number) {
		const url = this.URL_INCENTIVO + '/' + id.toString();
		return this.http.delete(url);
	}

	alterarIncentivo(id: number, incentivo: Incentivo) {
		const url = this.URL_INCENTIVO + '/' + id.toString();
		return this.http.put(url, incentivo);
	}

	ativarInativarIncentivo(idsIncentivo: number[], ativar: boolean) {
		const url = ativar ? this.URL_INCENTIVO + '/ativar' 
						   : this.URL_INCENTIVO + '/inativar';
		return this.http.post(url, {ids: idsIncentivo});
	}
}
