import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoVinculo } from '@core/models/tipo-vinculo';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class VinculoApiService {
	constructor(private http: HttpClient) {}

	URL_TIPO_VINCULO = environment.HOST_OPORTUNIDADE + '/' + 'vinculo';

	obterTipoVinculos(tipoVinculo: TipoVinculo, pagina: number, tamanho: number): Observable<TipoVinculo[]> {
		let params = new HttpParams();

		if (tipoVinculo.nome) {
			params = params.set('nome', tipoVinculo.nome);
		}

		if (tipoVinculo.ativo) {
			params = params.set('ativo', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<TipoVinculo[]>(this.URL_TIPO_VINCULO, { params: params });
	}

	obterTipoVinculo(id: number): Observable<TipoVinculo> {
		const url = this.URL_TIPO_VINCULO + '/' + id.toString();
		return this.http.get<TipoVinculo>(url);
	}

	incluirTipoVinculo(tipoVinculo: TipoVinculo) {
		return this.http.post(this.URL_TIPO_VINCULO, tipoVinculo);
	}

	alterarTipoVinculo(id: number, tipoVinculo: TipoVinculo) {
		const url = this.URL_TIPO_VINCULO + '/' + id.toString();
		return this.http.put(url, tipoVinculo);
	}

	excluirTipoVinculo(id: number) {
		const url = this.URL_TIPO_VINCULO + '/' + id.toString();
		return this.http.delete(url);
	}

	ativarInativarTipoVinculo(idsTipoVinculo: number[], ativar: boolean) {
		const url = ativar ? this.URL_TIPO_VINCULO + '/ativar' 
						   : this.URL_TIPO_VINCULO + '/inativar';
		return this.http.post(url, {ids: idsTipoVinculo});
	}
}
