import { Observable, Subscription, catchError, tap } from 'rxjs';

import { CargoProfissiografico } from '@app/core/models/perfil-profissiografico/cargo-profissiografico';
import { ConcursoProfissiografico } from '@app/core/models/perfil-profissiografico/concurso-profissiografico';
import { FiltroPesquisarLaudo } from '@app/core/models/perfil-profissiografico/filtro-pesquisar-laudo';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OrgaoProfissiografico } from '@app/core/models/perfil-profissiografico/orgao-profissiografico';
import { PerfilProfissiograficoApiService } from '../../core/services/perfil-profissiografico-api.service';
import { ResultadoPesquisarLaudo } from '@app/core/models/perfil-profissiografico/resultado-pesquisar-laudo';
import { SituacaoQuestionarioCargo } from '@app/core/models/perfil-profissiografico/situacao-questionario-cargo';
import { UsuarioService } from '@sigepe/sigepe-template';

@Injectable({
	providedIn: 'any'
})
export class PesquisarLaudoService {
	filtro = new FiltroPesquisarLaudo();
	somenteGestorPessoas = false;
	resultado$?: Observable<ResultadoPesquisarLaudo>;

	concursos$?: Observable<ConcursoProfissiografico[]>;
	cargos$?: Observable<CargoProfissiografico[]>;
	orgaos$?: Observable<OrgaoProfissiografico[]>;
	situacoes$?: Observable<SituacaoQuestionarioCargo[]>;

	subscriptions: Subscription[] = [];

	constructor(private pesquisarLaudoApi: PerfilProfissiograficoApiService, private messageService: MessageService, private usuarioService: UsuarioService) {}

	fecharSubscriptions() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	pesquisar(): void {
		this.resultado$ = this.pesquisarLaudoApi.pesquisarLaudo(this.filtro).pipe(
			catchError(error => {
				this.handleError(error, 'executar a pesquisa');
				throw error;
			})
		);
		this.filtro.calcularFirst();
	}

	paginar(page: number, size: number): void {
		this.filtro.page = page;
		this.filtro.size = size;
		this.pesquisar();
	}

	limpar(): void {
		this.filtro = new FiltroPesquisarLaudo();
		this.resultado$ = undefined;
		this.listarConcursos();
		this.listarSituacoes();
		this.orgaos$ = undefined;
		this.cargos$ = undefined;
	}

	listarConcursos(codigoSituacao?: string): void {
		this.concursos$ = this.pesquisarLaudoApi.getConcursos(codigoSituacao).pipe(
			catchError(error => {
				this.handleError(error, 'listar concursos');
				throw error;
			})
		);
	}

	listarOrgaosConcurso(idConcurso: number): void {
		this.orgaos$ = this.pesquisarLaudoApi.getOrgaosConcurso(idConcurso).pipe(
			catchError(error => {
				this.handleError(error, 'listar órgãos');
				throw error;
			})
		);
	}

	listarCargos(idConcurso: number, codigoOrgao: number): void {
		this.cargos$ = this.pesquisarLaudoApi.getCargos(idConcurso, codigoOrgao).pipe(
			catchError(error => {
				this.handleError(error, 'listar cargos');
				throw error;
			})
		);
	}

	listarSituacoes(): void {
		this.situacoes$ = this.pesquisarLaudoApi.getSituacaoLaudo().pipe(
			catchError(error => {
				this.handleError(error, 'listar situações');
				throw error;
			})
		);
	}

	atribuirEmLote(candidatosSelecionados: number[]): void {
		const nomeUsuario = this.usuarioService.getToken().name;
		const cpfUsuario = this.usuarioService.getToken().sub;
		this.messageService.clear();
		this.subscriptions.push(
			this.pesquisarLaudoApi
				.atribuirLaudo(candidatosSelecionados, nomeUsuario, cpfUsuario)
				.pipe(
					tap(() => {
						this.messageService.add({ severity: 'success', summary: 'Relatório(s) atribuído(s) com sucesso' });
						this.pesquisar();
					}),
					catchError(() => {
						this.handleError({}, 'atribuir relatório');
						return [];
					})
				)
				.subscribe()
		);
	}

	desatribuirEmLote(candidatosSelecionados: number[]): void {
		this.messageService.clear();
		this.subscriptions.push(
			this.pesquisarLaudoApi
				.desatribuirLaudo(candidatosSelecionados)
				.pipe(
					tap(() => {
						this.messageService.add({ severity: 'success', summary: 'Relatório(s) desatribuído(s) com sucesso' });
						this.pesquisar();
					}),
					catchError(() => {
						this.handleError({}, 'deatribuir relatório');
						return [];
					})
				)
				.subscribe()
		);
	}

	private handleError(error: any, descricao: string) {
		this.messageService.add({
			severity: 'error',
			summary: `Erro ao ${descricao}. `,
			detail: ' Tente novamente mais tarde.'
		});
	}
}
