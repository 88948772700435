import { HttpClient, HttpParams } from '@angular/common/http';

import { Idioma } from '@core/models/idioma';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class IdiomaApiService {
	constructor(private http: HttpClient) {}

	URL_IDIOMA = environment.HOST_OPORTUNIDADE + '/' + 'idioma';

	obterIdiomas(idioma: Idioma, pagina: number, tamanho: number): Observable<any[]> {
		let params = new HttpParams();

		if (idioma.nome) {
			params = params.set('nome', idioma.nome);
		}

		if (idioma.ativo) {
			params = params.set('ativo', 1);
		}

		if (idioma.libras) {
			params = params.set('libras', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any[]>(this.URL_IDIOMA, { params: params });
	}

	obterIdioma(id: number): Observable<Idioma> {
		const url = this.URL_IDIOMA + '/' + id.toString();
		return this.http.get<Idioma>(url);
	}

	incluirIdioma(idioma: Idioma) {
		return this.http.post(this.URL_IDIOMA, idioma);
	}

	alterarIdioma(id: number, idioma: Idioma) {
		const url = this.URL_IDIOMA + '/' + id.toString();
		return this.http.put(url, idioma);
	}

	excluirIdioma(id: number) {
		const url = this.URL_IDIOMA + '/' + id.toString();
		return this.http.delete(url);
	}

	ativarInativarIdioma(idsIdioma: number[], ativar: boolean) {
		const url = ativar ? this.URL_IDIOMA + '/ativar' 
						   : this.URL_IDIOMA + '/inativar';
		return this.http.post(url, {ids: idsIdioma});
	}
}
