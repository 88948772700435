<!--div class="bg-yellow-10 my-5 p-2">{{ pesquisarService.resultado$ | async | json}}</div-->

<p-toast position="top-right"></p-toast>
<div *ngIf="(pesquisarService.resultado$ | async) as resultado">
	<div class="br-table medium mt-4" *ngIf="resultado.items!.length; else semResultado">
		<div class="table-header">
			<div class="top-bar">
				<div class="col-sm-6 table-title" data-cy="titulo-tabela">
					<span *ngIf="resultado.metadata!.total === 1">Foi encontrado 1 resultado</span>
					<span *ngIf="resultado.metadata!.total! > 1">Foram encontrados {{ resultado.metadata!.total }} resultados</span>
				</div>
				<div *ngIf="usuarioGestor" class="col-sm-6 text-right">
					<p-button
						styleClass="primary"
						(click)="prepararLiberarQuestionariosEmLote()"
						data-cy="botao-liberar-em-lote"
						[disabled]="registrosSelecionados.length === 0"
						>Liberar múltiplos questionários</p-button
					>
				</div>
			</div>
		</div>
		<p-table
			[value]="resultado.items!"
			[lazy]="true"
			(onPage)="paginar($event)"
			[first]="pesquisarService.filtro.first!"
			[totalRecords]="resultado.metadata!.total"
			dataKey="idCargo"
			[(selection)]="registrosSelecionados"
			[rowHover]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="pesquisarService.filtro.size"
			[rowsPerPageOptions]="[10,20,50,100]"
			sortField="nome"
			data-cy="resultado-pesquisa"
		>
			<ng-template pTemplate="header">
				<tr>
					<th *ngIf="usuarioGestor" id="checkbox" class="checkbox">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th id="cabecalho-acoes" style="width: 10%">Ações</th>
					<th id="cabecalho-orgao">Órgão</th>
					<th id="cabecalho-cargo">Cargo</th>
					<th id="cabecalho-situacao">Situação do questionário</th>
					<th id="cabecalho-periodo">Período de liberação para respostas</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td *ngIf="usuarioGestor" class="checkbox" width="5%" style="vertical-align: baseline">
						<p-tableCheckbox [value]="rowData" [disabled]="rowData.situacao.codigo!='HOM'"></p-tableCheckbox>
					</td>
					<td headers="cabecalho-acoes">
						<sgp-botao-acao-profissiografico
							[listaAcoes]="rowData.acoesPossiveis"
							[idCargo]="rowData.idCargo"
							origemQuestionario="pesquisarQuestionario"
							(acaoRealizada)="pesquisarService.pesquisar()"
						></sgp-botao-acao-profissiografico>
					</td>
					<td headers="cabecalho-orgao">{{rowData.nomeOrgao}}</td>
					<td headers="cabecalho-cargo">
						<a
							[routerLink]="['/perfilProfissiografico/questionario', rowData.idCargo]"
							[state]="{ origem: 'pesquisarQuestionario' }"
							>{{rowData.nomeCargo}}</a
						>
					</td>
					<td headers="cabecalho-situacao">{{rowData.situacao.descricao}}</td>
					<td headers="cabecalho-periodo">{{rowData | dataItensResultadoQuestionario}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<ng-template #semResultado>
		<sgp-nenhum-resultado [exibir]="true" />
	</ng-template>
</div>

<p-dialog
	id="liberarQuestionarios"
	header="Liberar Questionário(s)"
	[(visible)]="showModalLiberarMultiplosQuestionarios"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-60vw text-left"
	[style]="{ minHeight: '350px'}"
>
	<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>
	<div class="row">
		<div class="col-sm-12">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-envio">Período:</label>
							<p-calendar
								[showIcon]="true"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-liberacao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa até dd/mm/aaaa"
								data-cy="campo-periodo-liberacao"
								[style]="{'minWidth':'350px', 'minHeight': '200px'}"
								[(ngModel)]="periodo"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="erroPeriodo"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>

					<div class="text-center" *ngIf="loadingResultado">Carregando...</div>

					<div class="card-footer">
						<div class="row mt-4">
							<div class="col text-right">
								<p-button
									label="Cancelar"
									(click)="esconderModalLiberarQuestionario()"
									styleClass="br-button secondary"
									*ngIf="!finalizado"
								></p-button>
								<p-button
									label="Fechar"
									(click)="esconderModalLiberarQuestionario()"
									styleClass="br-button primary ml-2"
									*ngIf="finalizado"
								></p-button>
								<p-button
									label="Confirmar"
									(click)="liberarEmLote()"
									styleClass="br-button primary ml-2"
									*ngIf="!finalizado"
								></p-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
